@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

body {
    margin: 0;
    font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #303030;
    color: #ffffff;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    background: #2c2d30;
}

nav > div {
    display: flex;
}

nav > div > button {
    margin-left: 1rem;
}

@media (max-width: 400px) {
    nav > h1 {
        max-width: 90px;
    }
}
