.centered {
    margin: auto;
    display: flex;
    padding: 10px;
    justify-content: center;
}

.verticalMiddle {
    margin: auto;
    display: flex;
    padding: 10px;
    justify-content: center;
    vertical-align: middle;
}

.link {
    font-size: 18px;
    color: #61A9FF;
}

h1 {
    font-size: 40px;
}

p {
    font-size: 16px;
}